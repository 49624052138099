/* @charset "utf-8"; */
/* CSS Document */

// SAKINA - START

/* THEME - START */

$THEME_PRIMARY_DARK_COLOR: #004E94
$THEME_PRIMARY_DARK_RED: 0
$THEME_PRIMARY_DARK_GREEN: 78
$THEME_PRIMARY_DARK_BLUE: 148
$THEME_PRIMARY_NORMAL_COLOR: #005DA3
$THEME_PRIMARY_NORMAL_RED: 0
$THEME_PRIMARY_NORMAL_GREEN: 93
$THEME_PRIMARY_NORMAL_BLUE: 163
$THEME_PRIMARY_LIGHT_COLOR: #1476BC
$THEME_PRIMARY_LIGHT_RED: 20
$THEME_PRIMARY_LIGHT_GREEN: 118
$THEME_PRIMARY_LIGHT_BLUE: 188
$THEME_PRIMARY_SOFT_COLOR: #2876BC
$THEME_PRIMARY_SOFT_RED: 40
$THEME_PRIMARY_SOFT_GREEN: 118
$THEME_PRIMARY_SOFT_BLUE: 188
$THEME_PRIMARY_ACCENT_COLOR: #29ABE2
$THEME_PRIMARY_ACCENT_RED: 41
$THEME_PRIMARY_ACCENT_GREEN: 171
$THEME_PRIMARY_ACCENT_BLUE: 226
$THEME_PRIMARY_HIGHLIGHT_COLOR: #6BB9FF
$THEME_PRIMARY_HIGHLIGHT_RED: 107
$THEME_PRIMARY_HIGHLIGHT_GREEN: 185
$THEME_PRIMARY_HIGHLIGHT_BLUE: 255

$THEME_SECONDARY_DARK_COLOR: #F26F21
$THEME_SECONDARY_DARK_RED: 242
$THEME_SECONDARY_DARK_GREEN: 111
$THEME_SECONDARY_DARK_BLUE: 33
$THEME_SECONDARY_NORMAL_COLOR: #F7931E
$THEME_SECONDARY_NORMAL_RED: 247
$THEME_SECONDARY_NORMAL_GREEN: 147
$THEME_SECONDARY_NORMAL_BLUE: 30

$THEME_TERTIARY_DARK_COLOR: #787878
$THEME_TERTIARY_DARK_RED: 120
$THEME_TERTIARY_DARK_GREEN: 120
$THEME_TERTIARY_DARK_BLUE: 120
$THEME_TERTIARY_NORMAL_COLOR: #B4B4B4
$THEME_TERTIARY_NORMAL_RED: 180
$THEME_TERTIARY_NORMAL_GREEN: 180
$THEME_TERTIARY_NORMAL_BLUE: 180
$THEME_TERTIARY_LIGHT_COLOR: #E6E6E6
$THEME_TERTIARY_LIGHT_RED: 230
$THEME_TERTIARY_LIGHT_GREEN: 230
$THEME_TERTIARY_LIGHT_BLUE: 230
$THEME_TERTIARY_SOFT_COLOR: #FFFFFF
$THEME_TERTIARY_SOFT_RED: 255
$THEME_TERTIARY_SOFT_GREEN: 255
$THEME_TERTIARY_SOFT_BLUE: 255

/* THEME - END */

/* INDICATOR - START */

$THEME_INDICATOR_SUCCESS_DARK_COLOR: #009245
$THEME_INDICATOR_SUCCESS_DARK_RED: 0
$THEME_INDICATOR_SUCCESS_DARK_GREEN: 146
$THEME_INDICATOR_SUCCESS_DARK_BLUE: 69
$THEME_INDICATOR_SUCCESS_NORMAL_COLOR: #14A659
$THEME_INDICATOR_SUCCESS_NORMAL_RED: 20
$THEME_INDICATOR_SUCCESS_NORMAL_GREEN: 166
$THEME_INDICATOR_SUCCESS_NORMAL_BLUE: 89
$THEME_INDICATOR_WARNING_COLOR: #F15A24
$THEME_INDICATOR_WARNING_RED: 241
$THEME_INDICATOR_WARNING_GREEN: 90
$THEME_INDICATOR_WARNING_BLUE: 36
$THEME_INDICATOR_FAIL_DARK_COLOR: #C1272D
$THEME_INDICATOR_FAIL_DARK_RED: 193
$THEME_INDICATOR_FAIL_DARK_GREEN: 36
$THEME_INDICATOR_FAIL_DARK_BLUE: 45
$THEME_INDICATOR_FAIL_NORMAL_COLOR: #D53841
$THEME_INDICATOR_FAIL_NORMAL_RED: 213
$THEME_INDICATOR_FAIL_NORMAL_GREEN: 56
$THEME_INDICATOR_FAIL_NORMAL_BLUE: 65
$THEME_INDICATOR_INFORMATION_COLOR: #0071BC
$THEME_INDICATOR_INFORMATION_RED: 0
$THEME_INDICATOR_INFORMATION_GREEN: 113
$THEME_INDICATOR_INFORMATION_BLUE: 188
$THEME_INDICATOR_SEVERITY_URGENT_COLOR: #FF0000
$THEME_INDICATOR_SEVERITY_URGENT_RED: 255
$THEME_INDICATOR_SEVERITY_URGENT_GREEN: 0
$THEME_INDICATOR_SEVERITY_URGENT_BLUE: 0
$THEME_INDICATOR_SEVERITY_HIGH_COLOR: #C92D00
$THEME_INDICATOR_SEVERITY_HIGH_RED: 201
$THEME_INDICATOR_SEVERITY_HIGH_GREEN: 45
$THEME_INDICATOR_SEVERITY_HIGH_BLUE: 0
$THEME_INDICATOR_SEVERITY_MEDIUM_COLOR: #F7941D
$THEME_INDICATOR_SEVERITY_MEDIUM_RED: 247
$THEME_INDICATOR_SEVERITY_MEDIUM_GREEN: 148
$THEME_INDICATOR_SEVERITY_MEDIUM_BLUE: 29
$THEME_INDICATOR_SEVERITY_LOW_COLOR: #D8CB30
$THEME_INDICATOR_SEVERITY_LOW_RED: 216
$THEME_INDICATOR_SEVERITY_LOW_GREEN: 203
$THEME_INDICATOR_SEVERITY_LOW_BLUE: 48

/* INDICATOR - END */

/* LIST - START */

$THEME_LIST_EVEN_COLOR: #FFFFFF
$THEME_LIST_EVEN_RED: 255
$THEME_LIST_EVEN_GREEN: 255
$THEME_LIST_EVEN_BLUE: 255
$THEME_LIST_EVEN_OPACITY: 0.2
$THEME_LIST_ODD_COLOR: #FFFFFF
$THEME_LIST_ODD_RED: 255
$THEME_LIST_ODD_GREEN: 255
$THEME_LIST_ODD_BLUE: 255
$THEME_LIST_ODD_OPACITY: 0.1

/* LIST - END */

/* CONVERSATION - START */

$THEME_CONVERSATION_CUSTOMER_COLOR: #E0BB16
$THEME_CONVERSATION_CUSTOMER_RED: 224
$THEME_CONVERSATION_CUSTOMER_GREEN: 187
$THEME_CONVERSATION_CUSTOMER_BLUE: 22
$THEME_CONVERSATION_DIVISION_COLOR: #943707
$THEME_CONVERSATION_DIVISION_RED: 148
$THEME_CONVERSATION_DIVISION_GREEN: 55
$THEME_CONVERSATION_DIVISION_BLUE: 7
$THEME_CONVERSATION_PRIVATE_COLOR: #F7941D
$THEME_CONVERSATION_PRIVATE_RED: 247
$THEME_CONVERSATION_PRIVATE_GREEN: 148
$THEME_CONVERSATION_PRIVATE_BLUE: 29
$THEME_CONVERSATION_PUBLIC_COLOR: #1C75BC
$THEME_CONVERSATION_PUBLIC_RED: 28
$THEME_CONVERSATION_PUBLIC_GREEN: 117
$THEME_CONVERSATION_PUBLIC_BLUE: 188
$THEME_CONVERSATION_DISABLED: #6D6E71
$THEME_CONVERSATION_DISABLED_RED: 109
$THEME_CONVERSATION_DISABLED_GREEN: 110
$THEME_CONVERSATION_DISABLED_BLUE: 113

/* CONVERSATION - START */

// SAKINA - END


// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SETUP - START

/* SEMANTIC ELEMENTS - START */

div.DivBackground
	+generateBackgroundGradientTopBottom($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

footer
	background-color: transparent
	background-position: top
	background-repeat: no-repeat
	background-size: cover

+generateInputPlaceholder($THEME_TERTIARY_NORMAL_COLOR)

/* SEMANTIC ELEMENTS - END */

/* NEUTRALIZATION - START */

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	background-color: transparent

*
	font-family: "Poppins-Regular"

/* NEUTRALIZATION - END */

// SETUP - END


// LAYOUT - START

/* GENERAL - START */

div.DivForm
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, p
		color: $THEME_TERTIARY_DARK_COLOR

div.DivCard
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCardFinish
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivForm
	div#divPercentageApproval, div#divPercentageConfirmation
		background-color: $THEME_SECONDARY_DARK_COLOR
		div#divSubPercentageProgress
			background-color: $THEME_SECONDARY_NORMAL_COLOR

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			a, span
				color: $THEME_TERTIARY_SOFT_COLOR

/* GENERAL - END */

/* HOME - START */

div#divBodyHome
	background-color: transparent

/* HOME - END */

/* DASHBOARD - START */

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			ul
				background: rgba(0,0,0,.05)
				li
					color: $THEME_TERTIARY_DARK_COLOR
					span:first-of-type
						background-color: $THEME_SECONDARY_DARK_COLOR
						color: $THEME_TERTIARY_SOFT_COLOR

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	ul.ListTableThumbnails, ul.ListTableTicket
		background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
	ul.ListTableConversation
		li
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
	ul.ListTableTicket
		li:nth-child(odd)
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
		span.TicketLow
			background-color: green
		span.TicketMedium
			background-color: orange
		span.TicketHigh
			background-color: red
	table
		thead
			tr
				th
					background-color: $THEME_PRIMARY_DARK_COLOR
		tbody
			tr:nth-child(even)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
			tr:nth-child(odd)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

form.FormInsertConversation, form.FormUploadFile
	table.TableListDetails
		color: $THEME_TERTIARY_DARK_COLOR


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	background-color: rgba(0, 0, 0, 0.6)
	// div#divLoadingBar
	// 	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

/* LOADING - END */

// LAYOUT - END


// LABEL - START

/* GENERAL - START */

form
	div.DivForm
		h3
			color: $THEME_SECONDARY_DARK_COLOR
		fieldset
			legend
				color: $THEME_SECONDARY_NORMAL_COLOR
			label
				color: $THEME_TERTIARY_DARK_COLOR
	label.LabelAttachment
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	label.LabelAttachmentDisable
		background: $THEME_CONVERSATION_DISABLED

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2, p
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivForm
		h3, h4
			color: $THEME_SECONDARY_DARK_COLOR
		label, .legend-title
			color: $THEME_TERTIARY_DARK_COLOR
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label
					color: $THEME_SECONDARY_NORMAL_COLOR
				h2
					color: $THEME_SECONDARY_DARK_COLOR
			div.DivHeaderStatus
				span
					color: $THEME_TERTIARY_SOFT_COLOR
				span.SpanSelectStatus
					background-color: $THEME_SECONDARY_DARK_COLOR
				span.SeverityUrgent
					background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
				span.SeverityHigh
					background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR
				span.SeverityMedium
					background-color: $THEME_INDICATOR_SEVERITY_MEDIUM_COLOR
				span.SeverityLow
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt, dd
							color: $THEME_TERTIARY_DARK_COLOR
				div.DivHeaderList
					ul
						li
							background-color: $THEME_TERTIARY_LIGHT_COLOR
							span
								color: $THEME_TERTIARY_DARK_COLOR

div.DivForm, form div.DivForm fieldset
	textarea#spanIncidentNote
		background-color: white
	textarea:disabled
		background-color: $THEME_TERTIARY_SOFT_COLOR


/* GENERAL - END */

/* NAVIGATION - START */

nav
	color: $THEME_TERTIARY_SOFT_COLOR
	figure
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-color: $THEME_TERTIARY_SOFT_COLOR
		div
			background-color: transparent
			img#imageProfilePicture
				background-color: transparent
			span#spanProfileNameInitial
				background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1, span
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivEmailNotRegistered
		p.PEmailNotRegistered, a.AContactUs, p.PVersion
			color: $THEME_TERTIARY_SOFT_COLOR

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		span.SpanHighlight
			background-color: $THEME_SECONDARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	color: $THEME_TERTIARY_SOFT_COLOR
	span.SpanTicketStatus
		background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	table.TableListDetailsAttachment
		label.LabelAttachment
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

/* TABLE - END */

/* DYNAMIC CONTAINER - START */

div.DivListControlButton
	label
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivTransparantContainer
	background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

div.DivNavigationControlButton
	input[type=radio] + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	input[type=radio]:checked + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationContent
				div.DivAttachmentContainer
					background-color: $THEME_TERTIARY_LIGHT_COLOR

.ProfileDivision
	background-color: $THEME_CONVERSATION_DIVISION_COLOR

.ProfileCustomer
	background-color: $THEME_CONVERSATION_CUSTOMER_COLOR

.PrivacyPrivate
	background-color: $THEME_CONVERSATION_PRIVATE_COLOR

.PrivacyPublic
	background-color: $THEME_CONVERSATION_PUBLIC_COLOR

/* DYNAMIC CONTAINER - END */

// LABEL - END


// INPUT -START

/* GENERAL - START */

input, select, textarea
	color: $THEME_TERTIARY_DARK_COLOR
	background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
	div.DivRadioButton
		background-color: transparent
		border-color: red
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivCheckBoxContainer
	div.DivCheckBox
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivRoundButtonContainer
	label
		color: $THEME_SECONDARY_DARK_COLOR !important

/* GENERAL - END */

/* THIRD PARTY - START */

.mat-form-field-appearance-fill .mat-form-field-flex
	color: $THEME_TERTIARY_DARK_COLOR
	background-color: none
	border-radius: 5px
	.mat-form-field-infix
		width: 100px

.mat-focused
	color: $THEME_TERTIARY_DARK_COLOR

.ng-select
	height: 36px
	background: #F5F6F6
	border-radius: 5px
	border: none
	font-size: 1em
	background-repeat: no-repeat
	background-size: $SPACE_LITTLE
	background-image: url(/assets/icons/sakina/icon_arrow_select.svg)
	background-position: calc(100% - 10px) center
	div.ng-select-container
		padding-top: 5px
		align-items: baseline
		div.ng-placeholder
			font-size: 0.8em
			color: #787878
		div.ng-value-container
			height: 27px
			margin-top: 5px
			margin-left: 10px
		span.ng-clear-wrapper
			display: none

.ng-dropdown-panel
	background-color: $THEME_TERTIARY_SOFT_COLOR


div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.ng-select-container
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - START

div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.ng-select-container
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - END


/* THIRD PARTY - END */

/* NAVIGATION - START */

/* NAVIGATION - END */

/* SIGN IN - START */

/* SIGN IN - END */

// INPUT - END


// LINK - START

/* GENERAL - START */

a.ADownload
	color: $THEME_TERTIARY_SOFT_COLOR

form
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button].ButtonPositive
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_SUCCESS_NORMAL_COLOR, $THEME_INDICATOR_SUCCESS_NORMAL_RED, $THEME_INDICATOR_SUCCESS_NORMAL_GREEN, $THEME_INDICATOR_SUCCESS_NORMAL_BLUE, $THEME_INDICATOR_SUCCESS_DARK_COLOR, $THEME_INDICATOR_SUCCESS_DARK_RED, $THEME_INDICATOR_SUCCESS_DARK_GREEN, $THEME_INDICATOR_SUCCESS_DARK_BLUE)
	input[type=button].ButtonNegative
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_FAIL_NORMAL_COLOR, $THEME_INDICATOR_FAIL_NORMAL_RED, $THEME_INDICATOR_FAIL_NORMAL_GREEN, $THEME_INDICATOR_FAIL_NORMAL_BLUE, $THEME_INDICATOR_FAIL_DARK_COLOR, $THEME_INDICATOR_FAIL_DARK_RED, $THEME_INDICATOR_FAIL_DARK_GREEN, $THEME_INDICATOR_FAIL_DARK_BLUE)

input[type=button].ButtonAttachment
	margin: 0px !important
	background-color: transparent

input[type=button].ButtonCopy
	background-color: transparent

input[type=button].ButtonAddTicket
	background-color: $THEME_SECONDARY_NORMAL_COLOR

input[type=button]:disabled
	background-color: #B7D7EF !important

// BRIMO SFART

input[type=button].ButtonArrowLeft
	background-image: url($URL_INPUT_RESOURCE + "input_arrow_left.svg")


// BRIMO END


/* GENERAL - END */

/* HOME - START */

input[type=button]#buttonNavigation
	background-color: transparent

/* HOME - END */

/* PRODUCT INFORMATION - START */

input[type=button]#buttonCalculator
	background-color: transparent

/* PRODUCT INFORMATION - END */

/* TABLE - START */

div.DivTableControl, div.DivTableContainer
	div.DivButtonFirst.Enable, div.DivButtonPrevious.Enable, div.DivButton
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	div.DivButtonNext.Enable, div.DivButtonLast.Enable
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE, $THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE)
	div.DivButtonFirst.Disable, div.DivButtonPrevious.Disable, div.DivButtonFirst, div.DivButtonPrevious
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE, $THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE)
	div.DivButtonNext.Disable, div.DivButtonLast.Disable, div.DivButtonNext, div.DivButtonLast
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)
	input[type=button]
		background-color: transparent
	span.SpanTablePage
		color: $THEME_TERTIARY_SOFT_COLOR

/* TABLE - END */

// LINK - END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			input[type=button]
				background-color: $THEME_SECONDARY_DARK_COLOR
			div.DivBranchCardInformation
				img
					background-color: $THEME_SECONDARY_DARK_COLOR
			p
				color: $THEME_TERTIARY_SOFT_COLOR

			label
				color: $THEME_TERTIARY_DARK_COLOR


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL END //


// BRIMO START

div.DivBodyGeneral
	header.HeaderProduct
		background-color: #1F8FE5
		color: white
	div#divMainDashboard
		div.DivBackgroundProduct, div.DivBackgroundPucrhasingDetail
			img.ImgIconAsri, img.ImgIconOto, img.ImgIconBicycle
				background-repeat: no-repeat
				background-image: url($URL_ICON_RESOURCE + "icon_asri_new.svg")
				background-position: center
				width: 150px
				height: 150px
				background-color: #EEF2F6
				border-radius: 50%
				margin-top: 70px
				background-size: 100px
			img.ImgIconOto
				background-image: url($URL_ICON_RESOURCE + "icon_oto_new.svg")
				background-size: 100px
			img.ImgIconBicycle
				background-image: url($URL_ICON_RESOURCE + "icon_sepeda_new.svg")
				background-size: 100px
		input[type=button].ButtonNext, input[type=button].ButtonNextFirstCard , input[type=button].ButtonNexPurchasing, input[type=button].ButtonPurchasingSummary
			background-color: #1078CA
			color: white
		input[type=button].ButtonNextFirstCardDisable
			background-color: #b5b5b5
			color: white
			cursor: default

// BRIMO END


// PRODUCT DETAIL START

div.DivContentPackage,div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing, div.DivSelectPackage
	+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
	div.DivHeaderPackage
		img
			width: 50px
			height: 50px
			margin-left: 20px
			margin-right: 10px
	hr
		border-bottom: 2px solid #cdd2d8
	img.ImgCheck
		content: url($URL_INPUT_RESOURCE + "input_check.svg")
		background-color: #0AB970
	img.ImgUnCheck, img.ImgUnCheckOverFlow
		content: url($URL_INPUT_RESOURCE + "input_uncheck.svg")
		background-color: #F75656

// PRODUCT DETAIL END



// BRIMO PRODUCT LIST START

div#divBodyDashboard
	div#divMainDashboard
		div.DivContentPackage
			div.DivHeaderPackage
				img.ImgAsri, img.ImgOto, img.ImgBicycle
					outline: 1px solid
					outline-color: #cdd2d8
					border-radius: 50%
					width: 50px
					height: 50px
					margin-right: 10px
					padding: 5px
					margin-left: 15px
				img.ImgAsri
					content: url($URL_ICON_RESOURCE + "icon_asri_new.svg")
				img.ImgOto
					content: url($URL_ICON_RESOURCE + "icon_oto_new.svg")
				img.ImgBicycle
					content: url($URL_ICON_RESOURCE + "icon_sepeda_new.svg")

// BRIMO PRODUCT LIST END


// BRIMO LANDING PAGE START

div.DivBodyGeneral
	div#divMainDashboard
		div.DivBackgroundAsriLandingPage
			img.ImgBrinsAsriLandingPage
				content: url($URL_ICON_RESOURCE + "icon_asri_page.svg")
				height: 50px
				margin-top: 40px
				background-size: 280px
				margin-left: 60px
		div.DivBackgroundOtoLandingPage
			img.ImgBrinsOtoLandingPage
				content: url($URL_ICON_RESOURCE + "icon_oto_page.svg")
				height: 50px
				margin-top: 40px
				background-size: 280px
				margin-left: 60px
		div.DivBackgroundBicycleLandingPage
			img.ImgBrinsBicycleLandingPage
				content: url($URL_ICON_RESOURCE + "icon_bicycle_page.svg")
				height: 50px
				margin-top: 40px
				background-size: 280px
				margin-left: 60px



// BRIMO LANDING PAGE END


// BRIMO POLCYHOLDER START

div#divBodyDashboard
	div.DivMainFirstCard
		div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
			div.DivFirstCard
				input
					border-bottom: 1px solid #CFCFCF
				textarea
					background-color: transparent
					border: 1px solid #CFCFCF
				.TextAreaAddress::placeholder
					color: #888888
					padding: 10px
				select
					background-color: transparent
					border-bottom: 1px solid black
					color: black

// BRIMO POLCYHOLDER END


// BRIMO ASRI PREMIUM SIMULATION START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivCardInformation
			border: 1px solid #00529C
			background-color: #EEF2F6
			div.DivWrapImgInformation
				img.ImgInformation
					content: url($URL_ICON_RESOURCE + "icon_info.svg")
					width: 20px
					margin: 0px
					margin-bottom: 10px
					margin-right: 10px

// BRIMO ASRI PREMIUM SIMULATION END


// BRIMO ASRI PREMIUM RESULT START

div#divMainDashboard
	div.DivWrapperListCardPremiumResult, div.DivWrapperPurchasingDetail
		// background-color: #1F8FE5
		div.DivContentFirstCard
			background-color: white
	div.DivWrapperListCardPremiumResult
		background-color: #1F8FE5


// BRIMO ASRI PREMIUM RESULT END


// BRIMO ASRI INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivCheckBoxContainer
					input[type=checkbox] + label
						border: 2px solid #1078CA
					input[type=checkbox]:checked + label:after
						color: white
						background: #1078CA


// BRIMO ASRI INSURED END


// BRIMO ASRI SUPPORTING DOCUMENT START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivDocumentCard
					background-color: #EEF2F6
					display: flex
					justify-content: center
					flex-direction: column
					input
						width: 100
						height: 100%
						background-repeat: no-repeat
						// opacity: 10
						background-color: red
						// z-index: 1
						// position: relative
						// margin-bottom: -100px
						// opacity: 0

// BRIMO ASRI SUPPORTING DOCUMENT END


// BRIMO ASRI PURCHASING DETAIL START

div#divMainDashboard
	div.DivWrapperPurchasingDetail
		div.DivAgreement
			border: 2px solid #CFCFCF
	div.DivCheckBoxContainerPurchasing
		input[type=checkbox] + label
			border: 2px solid #1078CA
		input[type=checkbox]:checked + label:after
			color: white
			background: #1078CA
	div.DivContainerCardInformation
		div.DivCardInformationPurchasing
			border: 1px solid #00529C
			background-color: #EEF2F6
			img.ImgInformation
				content: url($URL_ICON_RESOURCE + "icon_info.svg")
				width: 20px
				margin: 0px
				margin-bottom: 10px
				margin-right: 10px

// BRIMO ASRI PURCHASING DETAIL END


// BRIMO ASRI PURCHASING SUMMARY START

div#divMainDashboard
	hr
		color: #cbced1
	div.DivCardTimer, div.DivContainerBriva
		border: 1px solid #CFCFCF
	div.DivContainerBriva
		background-color:#EEF2F6
		input[type=button].ButtonCopyForBriva
			background-color: #1078CA
			color: white

// BRIMO ASRI PURCHASING SUMMARY END



// BRIMO OTO INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivDropdownPremiumSimulation
					select
						border-bottom: 1px solid #CFCFCF

// BRIMO OTO INSURED END
