/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyHome main
		padding: $SPACE_LARGE

	div.DivVerifierGeneral, div.DivCenterGeneral
		margin: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_LARGE

	input[type=button]#buttonNavigation
		left: $SPACE_LARGE

	div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
		margin-top: 60px

	div.DivMainAsideSearch
		form
			margin-right: $SPACE_LITTLE
			width: $WIDTH_LAYOUT_SIGNIN_COMPACT

	div.DivTableContainer
		margin-left: $SPACE_LITTLE

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 140px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 140px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

// SCREEN WIDTH 1250px - END


// SCREEN WIDTH 1140px - START

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

	div#divBodyDashboard	
		div#divMainDashboard
			div.DivWrapperList
				display: flex
				flex-direction: column

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none

	div.DivBodyGeneral
		div#divMainDashboard
			div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
				height: 100vh
				img.ImgBrinsOtoLandingPage, img.ImgBrinsOtoLandingPage , img.ImgBrinsBicycleLandingPage 
					height: 30px
					margin-top: 60px
					background-size: 280px
					margin-left: 20px
				div.DivButtonPage
					display: flex
					flex-direction: column
					.ButtonNext
						margin: 0px 20px 0px 20px
					p
						margin-top: 10px
						font-size: 16px
						font-weight: bold
						color: white
				div.DivContentDetailPage
					margin-top: 40px
					span
						width: 80%
						font-size: 60px
						font-weight: bold
						color: white
						margin-left: 20px
						font-style: italic
						display: inline-block
						line-height: 64px
					p
						margin-left: 20px
						width: 320px
						margin-top: 40px
						margin-bottom: 30px

	div.DivBodyGeneral
		margin-top: 0px
		div#divModalCustomAlert
			bottom: 0px

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivVerifierGeneral, div.DivCenterGeneral
		header.HeaderGeneral
			padding-top: 60px

	div.DivVerifierGeneral, div.DivCenterGeneral
		img#imageLogoHeader
			right: auto
			left: 0px

	div.DivTableContainer
		table
			thead
				tr
					th
						word-break: break-all
			tbody, tfoot
				tr
					td
						word-break: break-all
		ul.ListTableThumbnails
			justify-content: space-around
		ul.ListTableConversation
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						justify-content: flex-end

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 80px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 80px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

		

	
	// BRIMO LANDING PAGE START

	div.DivBodyGeneral
		div#divMainDashboard
			div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
				height: 100vh
				margin-top: 0px
				img.ImgBrinsAsriLandingPage, img.ImgBrinsOtoLandingPage, img.ImgBrinsBicycleLandingPage
					height: 30px
					margin-left: 20px
				div.DivContentDetailPage
					span
						font-size: 33px
						font-family: $URL_FAMILYQUATERNARY_TERTIARY
						line-height: 50px
						letter-spacing: 0.2px
						margin-top: -20px
					p
						font-size: 16px
						width: 260px
				div.DivButtonPage
					.ButtonNext
						margin-top: -20px		
										
	// BRIMO LANDING PAGE END

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 640px - START

@media screen and (max-width: 640px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivContainer
				div.DivFormHorizontalContainer
					flex-direction: column
					align-content: center
					align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //


	#divMainDashboard
		div.DivFormCardInside
			position: relative 
			margin: $SPACE_MEDIUM
			width: 100%
			z-index: 1
			margin-left: 0px
			div.DivFormBranch
				margin-bottom: $SPACE_BIG
				div.DivBranchCardInformation
					img
						border-radius: 50%
						padding: $SPACE_TINY
				input[type=button].ButtonShare
					background-position-x: 170px
				p
					margin-right: 200px
		div.DivFormNoBorder
			google-map
				.map-container
					border-radius: $SPACE_SMALL

	// BRIMO START GENERAL

	div.DivBodyGeneral
		margin-top: 0px
		div#divMainDashboard
			h2
				margin-left: 20px
			div.DivBackgroundLandingPage
				img.ImgBrinsOtoLandingPage
					height: 30px
					margin-top: 60px
					background-size: 280px
					margin-left: 20px
				div.DivButtonPage
					display: flex
					flex-direction: column
					.ButtonNext
						margin: 0px 20px 0px 20px
						width: 120px
					p
						margin-top: 10px
						font-size: 16px
						font-weight: bold
						color: white
						margin-left: 45px
				div.DivContentDetailPage
					margin-top: 40px
					span
						width: 80%
						font-size: 60px
						font-weight: bold
						color: white
						margin-left: 20px
						font-style: italic
						display: inline-block
						line-height: 64px
					p
						margin-left: 20px
						width: 320px
						margin-top: 40px
						margin-bottom: 30px
			label
				font-size: 14px
			div.DivWrapperListFormCard
				div.DivContentFirstCard
					div.DivFirstCardDiscount
						span.spanDiscount
							font-size: 14px
							font-family: $URL_FAMILYTERTIARY_SECONDARY
							color: black

	
	div#divBodyDashboard
		h3
			text-align: center + -20px
		div.DivMainFirstCard
			div.DivContentFirstCard 
				display: flex
				flex-direction: column

	div#divBodyDashboard	
		div.DivMainFirstCard
			div.DivWrapperListFormCard
				padding: 0px 16px 0px 16px
				div.DivContentFirstCard
					div.DivFirstCard
						div.DivPremiumSimulation
							label
								margin-right: 10px !important
							input
								width: 90%
			h3
				margin-left: 20px !important
			label
				margin-left: 20px 
			div.DivContentFirstCard
				margin-right: 20px
				display: flex
				flex-direction: column
				div.DivFirstCard:nth-child(2)
					// padding: 20px
					margin-bottom: 0px
					margin-top: -40px
			input[type=button].ButtonNextFirstCard, input[type=button].ButtonNextFirstCardDisable
				margin: 20px 20px 0 20px

	div#divMainDashboard
		div.DivWrapperPurchasingDetail
			padding: 0px 20px 0px 20px !important
		input[type=button].ButtonNexPurchasing
			margin: 0px 20px 0px 20px !important
		div.DivCheckBoxContainerPurchasing
			margin: 10px 0px 10px 20px
		div.DivCheckBoxContainerAuthorizedWorkshop
			margin: 0px 20px 10px 20px !important
		div.DivContainerCardInformation
			padding: 0px 20px 0px 20px
		div.DivWrapperListFormCard
			div.DivCardInformation
				margin: 0px 16px 0px 0px
				label
					font-size: 12px !important
			div.DivCardInformationForPackage
				margin-left: 16px
				margin-top: 40px
				label
					font-size: 14px !important
				div.DivFirstCardPackage
					margin-left: 0px

	div.DivModalAlertContainer
		div.DivModalAlertPolicy
			div.DivCustomAlertPolicy
				label
					font-size: 15px


	// BRIMO ASRI PURCHASING SUMMARY START

	div#divMainDashboard
		div.DivSideBySidePurchaseSummary
			display: flex
			justify-content: space-between
			padding: 0px 20px
			padding-left: 0px
			padding-right: 20px
			text-align: center
			span
				font-weight: 600
				margin-top: 10px
			span.spanForStatus
				background-color: #EFB403
				padding: 5px 10px
				border-radius: 5px
				color: white
				font-weight: normal

	// BRIMO LANDING PAGE START

	div.DivBodyGeneral
		div#divMainDashboard
			div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
				margin-top: 0px
				height: 100vh
				img.ImgBrinsAsriLandingPage, div.ImgBrinsOtoLandingPage, img.ImgBrinsBicycleLandingPage
					height: 30px
					margin-top: 40px
					margin-left: 20px
				div.DivContentDetailPage
					span
						font-size: 44px
						font-family: $URL_FAMILYQUATERNARY_TERTIARY
						letter-spacing: 0.2px
						margin-top: -20px
					p
						font-size: 14px
						width: 250px
				div.DivButtonPage
					width: 200px
					.ButtonNext
						margin-top: -20px	

	// BRIMO LANDING PAGE END


	// BRIMO ASRI PREMIUM RESULT START

	div#divMainDashboard
		div.DivWrapperListCardPremiumResult
			padding: 0px 20px 0px 20px !important
		
	// BRIMO ASRI PREMIUM RESULT END


// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important
	
	// BRIMO LANDING PAGE START

	div.DivBodyGeneral
		div#divMainDashboard
			div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
				margin-top: 0px
				height: 100vh
				img.ImgBrinsAsriLandingPage, img.ImgBrinsOtoLandingPage, img.ImgBrinsBicycleLandingPage
					height: 30px
					margin-left: 20px
				div.DivContentDetailPage
					span
						font-size: 33px
						font-family: $URL_FAMILYQUATERNARY_TERTIARY
						line-height: 50px
						letter-spacing: 0.2px
						margin-top: -20px
					p
						font-size: 14px
						width: 250px
				div.DivButtonPage
					width: 200px
					.ButtonNext
						margin-top: -20px		
										
	// BRIMO LANDING PAGE END

// SCREEN WIDTH 490px - END


// SCREEN WIDTH 360px - START

@media screen and (max-width: 360px)
	
	// BRIMO LANDING PAGE START

	div.DivBodyGeneral
		div#divMainDashboard
			div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
				margin-top: 0px
				height: 100vh
				img.ImgBrinsAsriLandingPage, img.ImgBrinsOtoLandingPage, img.ImgBrinsBicycleLandingPage
					height: 30px
					margin-left: 20px
				div.DivContentDetailPage
					span
						font-size: 33px
						font-family: $URL_FAMILYQUATERNARY_TERTIARY
						line-height: 50px
						letter-spacing: 0.2px
						margin-top: -20px
					p
						font-size: 14px
						width: 250px
				div.DivButtonPage
					width: 200px
					.ButtonNext
						margin-top: -20px	

			div.DivModalAlert
				div.DivCustomAlertPolicy
					display: flex
					justify-content: center
					align-items: center
					flex-direction: column
					margin-top: 20px
				div.DivCustomAlert,div.DivCustomAlertPolicy
					display: flex
					justify-content: center
					align-items: center
					flex-direction: column
					h3
						font-family: $URL_FAMILYTERTIARY_TERTIARY
						font-size: 20px
						color: #00529C
					label
						margin-top: 20px
						text-align: center
						font-size: 16px
						font-family: $URL_FAMILYTERTIARY_PRIMARY
					span
						font-family: $URL_FAMILYTERTIARY_TERTIARY
					div.DivButtonWrapperAlert
						display: flex
						justify-content: space-around
						gap: 50px
						margin-top: 20px
						margin-bottom: -10px
						input[type=button]
							border-radius: 10px
							padding: 10px 40px
							width: 100px
							border: 1px solid #1078CA
							font-family: $URL_FAMILYTERTIARY_TERTIARY
						input[type=button]:nth-child(1)
							background-color: white
							padding-left: 30px
							color: #1078CA
						input[type=button]:nth-child(2)
							background-color: #1078CA
							padding-left: 40px
							color: white	
			div.DivDropdownPremiumSimulation
				input.InputNumberPlat
					background-color: red

	div.DivModalAlertContainer
		div.DivModalAlertPolicy
			width: 350px
			height: 205px
		div.DivModalAlertPurhcasingDetail
			width: 370px
			height: 205px

	// BRIMO LANDING PAGE END


// SCREEN WIDTH 360px - END


// SCREEN RESPONSIVE MICROSITE KIOSK - START

//for ipad pro
@media screen and (width: 1024px), screen and (height: 1366px)
	div.DivMainProductContainer
		section.SectionCardContainer
			input.ImgCaptureForDisplay
				width: 77%
				height: 12%
				margin-top: -183px
				margin-left: 81px

//for ipad air
@media screen and (width: 820px), screen and (height: 1180)
	div.DivMainProductContainer
		section.SectionCardContainer
			input.ImgCaptureForDisplay
				height: 15%
				margin-top: -200px
				margin-left: 50px

//for ipad
@media screen and (width: 768px), screen and (height: 1024px)
	div.DivMainProductContainer
		section.SectionCardContainer
			input.ImgCaptureForDisplay
				height: 17%
				margin-top: -200px
				margin-left: 50px

//for iphone 6/7/8
@media screen and (max-width: 376px), screen and (max-height: 668px)
	div.DivMainProductContainer
		section.SectionCardContainer
			input.ImgCaptureForDisplay
				margin-top: -180px

//for iphone 5/se
@media screen and (max-width: 320px), screen and (max-height: 568px)
	div.DivMainProductContainer
		section.SectionCardContainer
			input.ImgCaptureForDisplay
				width: 70%
				height: 20%
				margin-top: -170px
				margin-left: 14px

@media screen and (max-width: 321px)
	ol.OlHorizontalStepperContainer
		margin-left: 20px
		margin-right: 20px
		li.LiHorizontalStepperContainerNonActive, li.LiHorizontalStepperContainerActive, li.LiHorizontalStepperContainerPreviousActive
			h3.H3TitleStepper
				font-size: 0.5em !important

@media screen and (max-width: 321px)
	ol.OlHorizontalStepperContainer
		margin-left: 20px
		margin-right: 20px
		li.LiHorizontalStepperContainerNonActive, li.LiHorizontalStepperContainerActive, li.LiHorizontalStepperContainerPreviousActive
			h3.H3TitleStepper
				font-size: 0.3em !important

// SCREEN RESPONSIVE MICROSITE KIOSK - END