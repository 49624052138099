/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPreffix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: $FONT_LABEL_H2
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h2, h3
			display: block
			margin-bottom: $SPACE_LITTLE
		h2
			margin-top: $SPACE_LITTLE
			font-size: $FONT_LABEL_H2
		h3
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		h4
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H4
		h4:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
		label.mat-form-field-label
			line-height: auto
			margin-top: -$SPACE_LITTLE
			margin-bottom: 0px
		div.DivHeaderHeadline
			div.DivSubHeaderHeadline
				label
					margin-top: 0
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span, p
			word-break: break-all
		span#SpanRupiah
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			word-break: inherit
		.mat-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block
		dl
			dt, dd
				font-size: $FONT_LABEL_HEADERINFO
		div.DivHeaderHeadline
			div.DivHeaderStatus
				span
					font-size: $FONT_LABEL_SUBTITLE
		fieldset
			div.DivHeaderInfo
				dl
					dt, dd
						font-size: $FONT_DATALIST_HEADERINFO

div.DivProfileInfo
	div.DivProfileInfoDetails
		a.Underline
			text-decoration: underline
	div.DivProfilePhoto
		span.SpanChatProfileNameInitial
			margin: 0px
			font-family: $URL_FAMILYSECONDARY_PRIMARY
			color: white
			font-size: $FONT_SPAN_PROFILENAMEINITIAL
			display: block
			text-align: center

// GENERAL - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
	ul
		list-style-type: none
		li
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LARGE
	ul.ListNavigation
		li.ListItemNavigation
			font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLEPAGE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_LABEL_GENERAL
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			span.SpanTicketTitle
				font-size: $FONT_LIST_TABLETITLE
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
						display: block
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 4px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
						margin-bottom: $SPACE_LITTLE
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600
	table.TableListDetailsAttachment
		input[type=file]
			display: none
		label.LabelAttachment
			display: block
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			+setBorderRadius(50%)

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLEPAGE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

// DYNAMIC CONTAINER - END


// LOADING - START

div#divCurtainLoading
	div#divLoadingMessage
		span#spanLoadingPercentage
			font-size: 1em
		span
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END


// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			h3:first-of-type
				margin-top: -15px
			h3
				margin-top: -15px
				font-size: 12pt
				align-items: center


// RETURN AMOUNT END


// INFO WINDOWS GOOGLE MAPS PLATFORM START

div.DivDynamicContainer
	map-info-window
		a
			font-weight: bold
			margin-bottom: $SPACE_LITTLE
		p
			margin-top: $SPACE_LITTLE

div.DivFormBranch
	span
		font-weight: bold
		text-transform: capitalize
		
// INFO WINDOWS GOOGLE MAPS PLATFORM END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			p
				position: absolute
				font-size: $SPACE_MEDIUM
				right: 0
				margin-top: 18px
				margin-right: 155px

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //


// BRIMO START

div.DivBodyGeneral
	header.HeaderProduct
		h3
			font-size: 18px !important
			text-align: center
			padding-right: 60px
			// width: 100%
			// margin-left: -40px
	div#divMainDashboard
		div.DivBackgroundProduct, div.DivBackgroundPucrhasingDetail
			p
				font-size: 20px
				color: white
		
		p, h2
			margin-top: 20px
			font-size: 16px
			color: #00529C
		label
			margin-top: 10px
			color: #333333
			margin-left: 20px
			text-align: center
			text-align: left
		
		div.DivContainerCardInformation
			div.DivCardInformationPurchasing
				div.DivFirstCard
					ol
						li
							font-size: 14px
							margin-left: 20px
		div.DivWrapperListFormCard
			div.DivCardInformation
				div.DivFirstCard
					ol
						span
							color: black
							font-size: 14px
							font-style: italic
						li
							font-size: 14px
							margin-left: 20px

.SpanContentPage
	cursor: pointer
	height: 12px
	width: 12px
	margin: 0 2px
	opacity: .6
	border-radius: 6px
	display: inline-block
.SpanContentPageDark
	background-color: #064F89
.SpanContentPageSoft
	background-color: #FFFF

// BRIMO END


// PRODUCT DETAIL START

div#divMainDashboard
	div.DivLabelDescription
		div,DivWrapperLabel
			display: flex
			span
				margin: 10px 0px 0px 20px
			label
				padding-left: 0px
				padding-right: 10px
				margin-left: 10px
	div.DivContentPackage,div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
		div.DivHeaderPackage
			div.DivDetailPackage
				p
					font-size: 14px !important
					margin-bottom: 0px
					margin-bottom: 0px
					margin-top: 18px !important
					color: #1F8FE5 !important
				label
					margin-left: 0px !important
					margin-top: 0px !important
					font-size: 16px !important
					margin-top: 20px !important
					color: #00529C
		span
			margin-bottom: 20px
			font-size: 26px
			margin-left: 20px
		h4
			margin-left: 20px
			margin-bottom: 20px
		div.DivImgForCheck
			label
				margin-left: 10px !important
				margin-top: 0px !important
			label.LabelMarginLeft20
				margin-left: 20px


// PRODUCT DETAIL END	



// BRIMO PRODUCT LIST START

div#divBodyDashboard	
	div#divMainDashboard
		h3
			margin-left: 40px
			font-size: 16px
			color: #00529C
			margin-top: 20px
		h4
			margin-left: 20px
			margin-right: 20px
			margin-top: 10px
			line-height: 25px

// BRIMO PRODUCT LIST END



// BRIMO GENERAL START

div.DivSideBySide
	display: flex
	justify-content: space-between
	padding: 0px 20px

// BRIMO GENERAL END


// BRIMO LANDING PAGE START

div.DivBodyGeneral
	div#divMainDashboard
		div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
			div.DivContentDetailPage
				span
					width: 80%
					font-size: 90px
					color: white
					margin-left: 60px
					font-style: italic
					display: inline-block
					line-height: 95px
				p
					font-size: 30px
					color: white
					margin-left: 60px
					margin-top: 50px
					margin-bottom: 30px
			div.DivButtonPage
				p
					color: white
					margin-bottom: 20px

// BRIMO LANDING PAGE END


// BRIMO POLCYHOLDER START

div#divBodyDashboard	
	div.DivMainFirstCard
		h3
			margin-left: 60px !important
			font-size: 18px !important
		label
			margin-left: 60px 
		div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
			div.DivFirstCard
				p
					margin-left: 0px
					margin-top: 0px
					margin-bottom: 10px
					span
						font-family: $URL_FAMILYTERTIARY_PRIMARY
						font-style: italic
				p.LabelPremiumSimulation
					margin-top: 10px
					margin-bottom: 10px
				span
					margin-bottom: 0px !important
					color: #00529C
					font-size: 16px
					margin-left: 0px
				h5
					font-size: 12px
					margin-bottom: 10px
					margin-left: 0px
				label
					margin-left: 0px
					margin-top: 0px
					font-size: 14px
				div.DivRadioButtonContainer
					label
						margin-top: 0px
						font-size: 16px
				div.InputSideBySide
					label:nth-child(1)
						margin-right: 40px
					label:nth-child(2)
						margin-right: 70px
						margin-left: 13px
					label:nth-child(3)
						margin-left: 55px

// BRIMO POLCYHOLDER END


// BRIMO ASRI PREMIUM SIMULATION START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivPremiumSimulation
					label
						margin-right: 10px !important

// BRIMO ASRI PREMIUM SIMULATION END


// BRIMO ASRI PREMIUM RESULT START

div#divMainDashboard
	div.DivWrapperListCardPremiumResult, div.DivWrapperPurchasingDetail
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivPremiumResult
					p
						font-size: 18px
						text-align: center
					div.DivSideBySide
						label
							margin-bottom: 20px
						label.LabelOverflow
							width: 170px

// BRIMO ASRI PREMIUM RESULT END


// BRIMO ASRI INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivCheckBoxContainer
					label
						margin-top: -3px
					
// BRIMO ASRI INSURED END


// BRIMO ASRI PURCHASING DETAIL START

div#divMainDashboard
	div.DivWrapperPurchasingDetail
		p
			font-size: 18px
		div.DivAgreement
			label, li
				font-size: 14px
			label
				margin-left: 5px
	div.DivCheckBoxContainerPurchasing
		label
			margin-top: -3px
			margin-left: 0px !important
			font-size: 14px

// BRIMO ASRI PURCHASING DETAIL END


// BRIMO ASRI PURCHASING SUMMARY START

div#divMainDashboard
	div.DivSideBySidePurchaseSummary
		span
			margin-top: 10px
	div.DivSideBySidePurchaseSummary:nth-child(2)
		span
			background-color: #EFB403
			padding: 5px 10px
			border-radius: 5px
			color: white
	div.DivCardTimer, div.DivContainerBriva
		p
			font-size: 12px
			color: #888888
		span
			font-size: 18px
			color: black
			margin-bottom: -10px
			margin-top: 10px
	div.DivContainerBriva
		p
			font-size: 12px
			color: #888888
	div.DivWrapperListFormCard
		div.DivCardInformation
			div.DivFirstCard
				label
					span
						color: black
						font-size: 14px
						font-style: italic

// BRIMO ASRI PURCHASING SUMMARY END



// BRIMO OTO INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				label
					margin-right: 5px !important
					span
						color: black
						font-size: 14px
						font-style: italicDivPriceValidation
			
// BRIMO OTO INSURED END
