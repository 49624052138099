/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// LABEL - START

/* GENERAL - START */

form
	div
		h3
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		fieldset
			legend
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			label
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			label.LabelPreffix,	label.LabelSuffix
				font-family: $URL_FAMILYPRIMARY_SECONDARY

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		p
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	div.DivForm
		h3
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		label, .legend-title
			font-family: $URL_FAMILYPRIMARY_PRIMARY
		span, p
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea#spanIncidentNote
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea:disabled
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea.TextareaDescriptionHeader
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label, h2
					font-family: $URL_FAMILYPRIMARY_SECONDARY
			div.DivHeaderStatus
				span
					font-family: $URL_FAMILYPRIMARY_PRIMARY
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt
							font-family: $URL_FAMILYPRIMARY_PRIMARY
						dd
							font-family: $URL_FAMILYPRIMARY_SECONDARY
				div.DivHeaderList
					ul
						li
							span
								font-family: $URL_FAMILYPRIMARY_PRIMARY

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			span
				font-family: $URL_FAMILYPRIMARY_SECONDARY

div.DivRoundButtonContainer
	label
		font-family: $URL_FAMILYPRIMARY_SECONDARY !important

/* GENERAL - END */

/* NAVIGATION - START */

nav
	figure
		span#spanProfileNameInitial
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		figcaption
			span#spanProfileName
				font-family: $URL_FAMILYPRIMARY_SECONDARY
			span#spanProfilePosition
				font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul
		li
			font-family: $URL_FAMILYPRIMARY_PRIMARY

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		span
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	form
		div.DivNotRegistered
			font-family: $URL_FAMILYPRIMARY_PRIMARY

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard
	div.DivHighlight
		span.SpanHighlight
			font-family: $URL_FAMILYPRIMARY_SECONDARY

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			font-family: $URL_FAMILYPRIMARY_SECONDARY

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	span.SpanTablePage
		font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul.ListTableThumbnails
		figure
			figcaption
				font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul.ListTableTicket, ul.ListTableConversation
		span.SpanTicketNumber, span.SpanConversationDate
			font-family: $URL_FAMILYPRIMARY_PRIMARY
		span.SpanTicketTitle, span.SpanConversationTitle
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		dl
			dt
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			dd
				font-family: $URL_FAMILYPRIMARY_SECONDARY
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-family: $URL_FAMILYSECONDARY_PRIMARY

/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	span#spanLoadingPercentage
		font-family: $URL_FAMILYTERTIARY_TERTIARY

/* LOADING - END */

// LABEL - END


// INPUT - START

/* GENERAL - START */

form
	div
		fieldset
			input
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			div.DivConversationHeader
				span.SpanConversationDate
					font-family: $URL_FAMILYPRIMARY_PRIMARY
				span.SpanConversationTitle
					font-family: $URL_FAMILYPRIMARY_SECONDARY

/* GENERAL - END */

// INPUT - END


/* THIRD PARTY */

// DROPDOWN MULTIPLE -START

div.DivForm
	div.DivFormMultipleDropdown,div.DivFormSingleDropdown
		span, p
				font-family: $URL_FAMILYPRIMARY_PRIMARY

// DORPDOWN MULTIPLE -END


// BRIMO GENERAL START

div.DivBodyGeneral
	header.HeaderProduct
		h3
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div#divMainDashboard
		div.DivBackgroundProduct, div.DivBackgroundPucrhasingDetail
			p
				font-family: $URL_FAMILYTERTIARY_TERTIARY
		p, h2
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		label
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		div.DivContentPackage,div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
			div.DivHeaderPackage
				div.DivDetailPackage
					label
						font-family: $URL_FAMILYTERTIARY_TERTIARY
		input[type=button].ButtonNext
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		input
			font-family: $URL_FAMILYTERTIARY_QUATERNARY
			color: black
		input:focus
			color: black
			font-family: $URL_FAMILYTERTIARY_QUATERNARY
		input:focus::after
			color: black
			font-family: $URL_FAMILYPRIMARY_SECONDARY

		div.DivContentFirstCard
			div.DivFirstCard
				div.DivPremiumResult
					div.DivFontAvenirDemi
						label:nth-child(1)
							font-family: $URL_FAMILYTERTIARY_SECONDARY !important
					div.DivSideBySide
						label:nth-child(2)
							font-family: $URL_FAMILYTERTIARY_QUATERNARY
					div.DivSideBySide:nth-child(5)
						label:nth-child(1)
							font-family: $URL_FAMILYTERTIARY_QUATERNARY

		div.DivContainerCardInformation
			div.DivCardInformationPurchasing
				div.DivFirstCard
					ol
						li
							font-family: $URL_FAMILYTERTIARY_SECONDARY

		div.DivSubTitleAgreement
			ul
				li
					font-family: $URL_FAMILYTERTIARY_SECONDARY
					
		div.DivWrapperListFormCard
			div.DivCardInformation
				div.DivFirstCard
					ol
						li
							font-family: $URL_FAMILYTERTIARY_SECONDARY
			div.DivContentFirstCard
				div.DivFirstCard
					textarea::placeholder
						font-family: $URL_FAMILYTERTIARY_SECONDARY


// BRIMO PRODUCT LIST START

div#divBodyDashboard	
	div#divMainDashboard
		h3
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		h4, h5
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		div.DivContentPackage
			span
				font-family: $URL_FAMILYTERTIARY_SECONDARY

// BRIMO PRODUCT LIST END


// BRIMO LANDING PAGE START

div.DivBodyGeneral
	div#divMainDashboard
		div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
			div.DivContentDetailPage
				span
					font-family: $URL_FAMILYTERTIARY_TERTIARY
				p
					font-family: $URL_FAMILYTERTIARY_SECONDARY
					
// BRIMO LANDING PAGE END


// BRIMO POLCYHOLDER START

div#divBodyDashboard	
	div.DivMainFirstCard
		h3
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		label
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
			div.DivFirstCard
				p
					font-family: $URL_FAMILYTERTIARY_TERTIARY
				span
					font-family: $URL_FAMILYTERTIARY_QUATERNARY
				label
					font-family: $URL_FAMILYTERTIARY_SECONDARY
				::placeholder
					font-family: $URL_FAMILYTERTIARY_QUATERNARY
				textarea.TextAreaAddress::placeholder
					font-family: $URL_FAMILYTERTIARY_SECONDARY
				select
					font-family: $URL_FAMILYTERTIARY_QUATERNARY
					font-size: 16px
		input[type=button].ButtonNextFirstCard
			font-weight: bold
		div.DivAgreement
			li
				font-family: $URL_FAMILYTERTIARY_SECONDARY

// BRIMO POLCYHOLDER END


// BRIMO ASRI PURCHASING DETAIL START

div#divMainDashboard
	input[type=button].ButtonNexPurchasing
		font-family: $URL_FAMILYTERTIARY_TERTIARY

// BRIMO ASRI PURCHASING DETAIL END


// BRIMO ASRI PURCHASING SUMMARY START

div#divMainDashboard
	div.DivSideBySidePurchaseSummary
		span
			font-family: $URL_FAMILYTERTIARY_SECONDARY
	div.DivSideBySidePurchaseSummary:nth-child(2)
		span
			font-family: $URL_FAMILYTERTIARY_SECONDARY
	div.DivSideBySidePurchaseSummary:nth-child(6)
		label
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div.DivCardTimer, div.DivContainerBriva
		p
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		span
			
			font-family: $URL_FAMILYTERTIARY_SECONDARY
	div.DivContainerBriva
		p
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		input[type=button].ButtonCopyForBriva
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	input[type=button].ButtonPurchasingSummary
		font-family: $URL_FAMILYTERTIARY_TERTIARY

// BRIMO ASRI PURCHASING SUMMARY END


// BRIMO END

div.DivMainProductContainer, ol.OlHorizontalStepperContainer
	h3, h4, h5, label, p, span
		font-family: $URL_FAMILYQUATERNARY_PRIMARY