/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// HYPERLINK - START

a.AClear
	color: inherit
	text-decoration: none
	border: none

a.AClear:link
	color: inherit
	text-decoration: none
	border: none

a.AClear:visited
	color: inherit
	text-decoration: none
	border: none

a.AClear:hover
	color: inherit
	text-decoration: none
	border: none

a.AClear:active
	color: inherit
	text-decoration: none
	border: none

a.AUnderline
	color: inherit
	text-decoration: underline

a.AUnderline:link
	color: inherit
	text-decoration: underline

a.AUnderline:visited
	color: inherit
	text-decoration: underline

a.AUnderlinek:hover
	color: inherit
	text-decoration: underline

a.AUnderline:active
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:link
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:visited
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:hover
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownloade:active
	color: inherit
	text-decoration: underline
	font-weight: 600

nav
	figure
		a
			padding: $SPACE_TINY
			display: inline-block

a.ForgotPassword
	float: right
	margin-top: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE
	// font-size: $fontsize_link_general
	display: block
	text-decoration: underline

a.SignUp
	text-decoration: underline

// HYPERLINK - END


// GENERAL - START

form
	input[type=button]
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: 100%
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=button]:first-of-type
		margin-top: $SPACE_MEDIUM
	input[type=button]:last-of-type
		margin-bottom: $SPACE_MEDIUM
	input[type=button].ButtonRight
		margin-top: $SPACE_LITTLE
		width: 20%
		float: right
	input[type=button].ButtonRight
		margin-bottom: $SPACE_LITTLE
		width: 20%
		float: right
	div.DivFormButtonContainer
		display: flex
		justify-content: space-between
		input[type=button]
			margin: $SPACE_LITTLE
		input[type=button]:first-of-type
			margin-left: 0px
		input[type=button]:last-of-type
			margin-right: 0px
	div.DivEmailNotRegistered
		display: flex
		flex-direction: column
		p
			align-items: center
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE

input[type=button].ButtonCopy, input[type=button].ButtonDelete, input[type=button].ButtonPDF, input[type=button].ButtonRemove, input[type=button].ButtonAttachment, input[type=button].ButtonDownload, input[type=button].ButtonAddTicket
	width: $SIZE_ICON_LDPI
	height: $SIZE_ICON_LDPI
	vertical-align: middle
	display: inline-block
	background-size: contain
	background-position: center
	background-repeat: no-repeat
	border: none

input[type=button].ButtonAttachment
	margin: 0px
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI

input[type=button].ButtonAddTicket
	width:$SIZE_ICON_MDPI
	height: $SIZE_ICON_MDPI
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_MDPI $SIZE_ICON_MDPI

input[type=button].ButtonShare
	background-size: 25px
	width: $SIZE_ICON_LDPI
	height: $SIZE_ICON_LDPI
	vertical-align: middle
	display: inline-block
	background-position: center
	background-repeat: no-repeat
	border: none
	background-position-x: 140px

form
	input[type=button].inputAbout
		display: block
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: auto
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=file]
		display: none

form input[type=button].ButtonDelete
	margin: 0px
	+setBorderRadius($RADUIS_LINK_BUTTONTABLE)

// GENERAL - END


// HOME - START

input[type=button]#buttonNavigation
	position: absolute
	top: 40px
	left: 40px
	width: 30px
	height: 30px
	border: none

// HOME - END


// TABLE - START

div.DivTableControl, div.DivListControl, div.DivTableContainer
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, input[type=button], div.DivButton
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		border: none
		background-repeat: no-repeat
		background-size: cover
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, div.DivButton
		margin-left: $SPACE_TINY
		margin-right: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
		display: inline-block
	div.TableButtonContainer
		text-align: center
	input[type=button].ButtonDownload, input[type=button].ButtonRemove
		background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI
div.DivTableContainer
	ul.ListTableThumbnails
		div.DivButton
			margin-top: $SPACE_LITTLE
		input[type=button].ButtonDownload, input[type=button].ButtonRemove
			margin: 0px
	table.TableListDetails
		td
			div.DivButton
				margin-left: 0px
				height: $SPACE_HUGE
				width: $SPACE_HUGE
				display: flex
				align-content: center
				flex-direction: column
				align-items: center
				+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
				input[type=button].ButtonRemove, input[type=button].ButtonDownload
					margin: 0px
					background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT
	table.TableListDetailsAttachment
		input[type=button].ButtonRemove, input[type=button].ButtonDownload, input[type=button].ButtonAttachment
			margin: 0px -0.25px
			background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT

ul.ListTableThumbnails
	li
		cursor: pointer
		cursor: hand

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivListControl
	div.DivListControlButton
		input[type=button]
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			border: none
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_TINY
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		input[type=button]:first-of-type
			margin-left: $SPACE_TINY
		input[type=button]:last-of-type
			margin-right: $SPACE_TINY
	div.DivNavigationControlButton
		input[type=radio]
			display: none
		input[type=radio] + label
			width: 120px
			height: 30px
			border: none
			text-align: center
			padding-top: 10px
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_LITTLE
			font-family: $URL_FAMILYPRIMARY_PRIMARY
			font-size: 1em
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		label
			margin-left: auto
			margin-right: auto

// DYNAMIC CONTAINER - END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			input[type=button]
				margin-top: $SPACE_LITTLE
				height: $HEIGHT_LINK_BUTTON
				width: 100%
				text-align: center
				font-size: $FONT_LINK_GENERAL
				border: none
				position: absolute
				left: 0px
				border-top-right-radius: 0px
				border-top-left-radius: 0px


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL END //



// BRIMO START

input[type=button].ButtonArrowLeft
	width: $SIZE_ICON_LDPI
	height: $SIZE_ICON_LDPI
	vertical-align: middle
	display: inline-block
	background-size: contain
	background-position: center
	background-repeat: no-repeat
	border: none
	background-color: transparent
		
// BRIMO END


