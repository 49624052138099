/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END



// LOADING - START

+generateKeyFrames(animationSpin)
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)

img#imageLoadingCircle
	+useAnimation(animationSpin, 1, 0, infinite)
	+setAnimationTimingFunction(linear)

+generateKeyFrames(dotFalling)
	0%
		box-shadow: 9999px -30px 0 0 rgba(255, 255, 255, 0)
	25%, 50%, 75%, 100%
		box-shadow: 9999px 0 0 0 #FFF

// LOADING - END


// NAVIGATION - START

main.MainShow
	+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
	+setTransition(all, 0.5, ease-in)
main.MainHide
	+setSlideTransform(-30, 120px, -100px, 0.6, 0.6, 0.6)
	+setTransition(all, 0.5, ease-out)

+generateKeyFrames(animationNavigationShow)
	0%
		margin-left: $SPACE_NAVIGATION_HIDE
		+setOpacity(0.0)
	100%
		margin-left: $SPACE_NAVIGATION_SHOW
		+setOpacity(1.0)

+generateKeyFrames(animationNavigationHide)
	0%
		margin-left: $SPACE_NAVIGATION_SHOW
		+setOpacity(1.0)
	100%
		margin-left: $SPACE_NAVIGATION_HIDE
		+setOpacity(0.0)

nav.NavShow
	+useAnimation(animationNavigationShow, 0.4, 0, 1)
	+setAnimationTimingFunction(ease-in)

nav.NavHide
	+useAnimation(animationNavigationHide, 0.4, 0, 1)
	+setAnimationTimingFunction(ease-out)

+generateKeyFrames(animationNavigationItemShow)
	0%
		margin-left: $SPACE_NAVIGATIONITEM_HIDE
		+setOpacity(0.0)
	100%
		margin-left: $SPACE_NAVIGATIONITEM_SHOW
		+setOpacity(1.0)

+generateKeyFrames(animationNavigationItemHide)
	0%
		margin-left: $SPACE_NAVIGATIONITEM_SHOW
	100%
		margin-left: $SPACE_NAVIGATIONITEM_HIDE
		+setOpacity(0.0)

+generateKeyFrames(animationCheckShow)
	0%
		height: 0px
	100%
		height: auto

+generateKeyFrames(animationCheckHide)
	0%
		height: auto
	100%
		height: 0px

nav
	input[type=checkbox]
		display: none

nav.NavNone
	display: none

nav.NavHide
	ul.ListNavigation
		li.ListItemNavigation
			input[type=checkbox].CheckBoxNavigationTrigger:checked + ul.ListSubNavigation
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].CheckBoxNavigationTrigger + ul.ListSubNavigation
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium:checked + ul.ListSubNavigationCalculatePremium
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium + ul.ListSubNavigationCalculatePremium
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)

nav.NavShow
	ul.ListNavigation
		li.ListItemNavigation
			input[type=checkbox].CheckBoxNavigationTrigger:checked + ul.ListSubNavigation
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].CheckBoxNavigationTrigger + ul.ListSubNavigation
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium:checked + ul.ListSubNavigationCalculatePremium
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium + ul.ListSubNavigationCalculatePremium
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)

// NAVIGATION - END


// ANIMATION MICROSITE KIOKS - START

/* lineup class and keyframes */
.TransitionLineUp
	animation: 2s animationLineUp ease-out

+generateKeyFrames(animationLineUp)
	0%
		opacity: 0
		transform: translateY(80%)
	20%
		opacity: 0
	50%
		opacity: 1
		transform: translateY(0%)
	100%
		opacity: 1
		transform: translateY(0%)

// ANIMATION MICROSITE KIOKS - end